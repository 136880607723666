import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteTopicModal from "../modal/deletetopic";
import DeleteLessonModal from "../modal/deletelesson";
import DeleteChapterModal from "../modal/deletechapter";
import axios from "axios";
import Loader from "../modal/loader";
import ShowMessage from "../modal/showmessage";

import {
  homepage,
  topicpage,
  lessonpage,
  chapterpage,
  header,
  background_img_type,
  button_img_type,
  jsonHeader,
} from "../config";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class CourseModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseTopic: "",
      isChapterModelVisible: false,
      chapter_id: "",
      formIsValid: true,
      errors: "",
      chapter_name: "",
      chapter_cat_name: "",
      categoryLists: "",
      chapter_template_type: "",
      chapter_bg_image: "",
      chapter1_bg_image: "",
      chapter1_bg_image: "",
      sortby: "",
      use_template: "",
      isBtnDisable: false,
      isEditCourseVisible: false,
      isLessonModelVisible: false,
      lesson_id: "",
      lesson_name: "",
      lesson_btn_type: "",
      lesson_btn_color: "#ffffff",
      lesson_btn_font_color: "#000000",
      lesson_btn_height: "",
      lesson_btn_width: "",
      lesson_btn_image: "",
      lesson1_btn_image: "",
      lesson_btn_icon: "",
      lesson_bg_image: "",
      lesson1_bg_image: "",
      lesson1_btn_icon: "",
      isTopicModelVisible: false,
      lesson_btn_font_size: "",
      btn_color: "#ffffff",
      btn_height: "",
      btn_width: "",
      btn_position: "",
      btn_font_size: "",
      btn_font_color: "#000000",
      topic_btn_image: "",
      topic_bg_image: "",
      topic1_bg_image: "",
      name: "",
      topic_id: "",
      topicDeleteId: "",
      topicDeleteList: "",
      isDeleteTopicSuccess: false,
      coursename: "",
      coursebg1: "",
      courseimg1: "",
      course_id: "",
      isApiPopUp: false,
      isDeleteLessonSuccess: false,
      showMessage: "",
      errorMessage: "",
      isMessagePopUp: false,
      isMessagePopUpError: false,
      lessonDeleteId: "",
      courseDeleteList: "",
      isDeleteSuccess: false,
      chapterDeleteId: "",
      sectionDeleteId: "",
    };
  }

  chapterFormClose = () => {
    this.setState({ isChapterModelVisible: false });
    this.chapterFormReset();
  };

  handleEditChapter = (chapter_id) => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      this.setState({ isApiPopUp: true });
      axios
        .get(chapterpage.getChapterById + "/" + `${chapter_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({
            errors: "",
            chapter_name:
              res.data.data.name != "null" ? res.data.data.name : "",
            chapter_template_type:
              res.data.data.template_type != "null"
                ? res.data.data.template_type
                : "",
            chapter_cat_name:
              res.data.data.category_id != "null"
                ? res.data.data.category_id
                : "",
            use_template:
              res.data.data.use_template != "null"
                ? res.data.data.use_template
                : "",
            chapter_use_template:
              res.data.data.use_template != "null"
                ? res.data.data.use_template
                : "",
            sound_level:
              res.data.data.sound_level != "null"
                ? res.data.data.sound_level
                : "",
            chapter_bg_image:
              res.data.data.chapter_bg_image != "null"
                ? res.data.data.chapter_bg_image
                : "",
            chapter1_bg_image:
              res.data.data.chapter_bg_image != "null"
                ? res.data.data.chapter_bg_image
                : "",
            chapter_id: res.data.data._id != "null" ? res.data.data._id : "",
            isChapterModelVisible: true,
            isApiPopUp: false,
            lesson_id:
              res.data.data.lesson_id != "null" ? res.data.data.lesson_id : "",
            sortby: res.data.data.sortby != "null" ? res.data.data.sortby : "",
          });
          this.getCategotyList();
        })
        .catch((err) => {
          this.setState({ isApiPopUp: false });
          console.log(err);
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handledeleteChapter = () => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      this.setState({ isApiPopUp: true });
      axios
        .delete(
          chapterpage.deleteChapter +
            "/" +
            `${this.state.chapterDeleteId}?c=${today}`,
          {
            headers: header,
          }
        )
        .then((res) => {
          this.getCourseTopic(this.state.course_id);
          this.setState({
            isMessagePopUp: true,
            isApiPopUp: false,
            isDeleteSuccess: false,
            showMessage: "Deleted Chapter successfully",
          });
          setTimeout(
            () => this.setState({ showMessage: "", isMessagePopUp: false }),
            2000
          );
          //   document
          //     .getElementById("section" + section_id)
          //     .classList.remove("show");
          document
            .getElementById("aaa" + this.state.sectionDeleteId)
            .classList.add("collapsed");
        })
        .catch((error) => {
          this.setState({
            isMessagePopUpError: true,
            showMessage: "Chapter not deleted",
            isApiPopUp: false,
            isDeleteSuccess: false,
          });
          setTimeout(
            () =>
              this.setState({
                showMessage: "",
                isMessagePopUpError: false,
              }),
            4000
          );
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleChapterValidation = () => {
    const {
      chapter_name,
      chapter_cat_name,
      chapter_use_template,
      chapter_template_type,
      use_template,
      chapter_bg_image,
      sortby,
    } = this.state;
    let errors = {};

    let formIsValid = true;
    this.setState({ errors });

    if (chapter_name == "") {
      formIsValid = false;
      errors["chapter_name"] = "Chapter name is required!";
    }
    // else if (chapter_cat_name == "") {
    //   formIsValid = false;
    //   errors["chapter_cat_name"] = "Chapter category is required !";
    // }
    else if (
      chapter_template_type == "" ||
      chapter_template_type == undefined
    ) {
      formIsValid = false;
      errors["chapter_template_type"] = "Template type is required !";
    } else if (sortby == "") {
      errors["sortby"] = "Position is required !";
      formIsValid = false;
    } else if (use_template == "" && chapter_use_template == undefined) {
      errors["use_template"] = "Select file !";
      formIsValid = false;
    } else if (chapter_use_template.name != null) {
      var extension = chapter_use_template.name.slice(
        Math.max(0, chapter_use_template.name.lastIndexOf(".") + 1) || Infinity
      );
      if (
        chapter_template_type.toLowerCase() == "jpg" ||
        chapter_template_type.toLowerCase() == "png"
      ) {
        if (extension != chapter_template_type.toLowerCase()) {
          errors["use_template"] = "Invalid file extension !";
          formIsValid = false;
        }
      } else if (
        chapter_template_type.toLowerCase() == "html" ||
        chapter_template_type.toLowerCase() == "swf"
      ) {
        if (extension != "zip") {
          errors["use_template"] = "Invalid file extension only zip allowed!";
          formIsValid = false;
        }
      } else if (chapter_template_type.toLowerCase() == "video") {
        if (extension != "mp4") {
          errors["use_template"] = "Invalid file extension only mp4 allowed!";
          formIsValid = false;
        }
      }
      //  if (chapter_use_template == "") {
    }
    //
    else if (chapter_bg_image == "") {
      errors["chapter_bg_image"] = "Background image is required !";
      formIsValid = false;
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  handleDeleteChapter = (chapter_id, section_id) => {
    this.setState({
      isDeleteSuccess: true,
      chapterDeleteId: chapter_id,
      sectionDeleteId: section_id,
    });
  };

  handleAddChapter = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);
      if (this.handleChapterValidation()) {
        this.setState({ isApiPopUp: true });
        const {
          chapter_id,
          lesson_id,
          chapter_name,
          chapter_use_template,
          chapter_template_type,
          chapter_title_image,
          sound_level,
          chapter_cat_name,
          use_template,
          chapter_bg_image,
          sortby,
          course_id,
        } = this.state;
        const formData = new FormData();
        formData.append("course_id", course_id);
        formData.append("lesson_id", lesson_id);
        formData.append("name", chapter_name);
        formData.append("category_id", chapter_cat_name);
        formData.append("template_type", chapter_template_type);
        formData.append("use_template", chapter_use_template);
        formData.append("old_use_template", use_template);
        formData.append("title_image", chapter_title_image);
        formData.append("sound_level", sound_level);
        formData.append("sortby", sortby);
        formData.append("chapter_bg_image", chapter_bg_image);

        if (chapter_id) {
          formData.append("chapter_id", chapter_id);
          axios
            .post(`${chapterpage.updateChapter}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.chapterFormClose();
              this.getCourseTopic(course_id);
              this.setState({
                isMessagePopUp: true,
                showMessage: "Update chapter successfully",
                isApiPopUp: false,
              });
              setTimeout(
                () => this.setState({ showMessage: "", isMessagePopUp: false }),
                2000
              );
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                showMessage: "Chapter not update",
                isApiPopUp: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUpError: false,
                  }),
                2000
              );
            });
        } else {
          axios
            .post(`${chapterpage.addChapter}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.setState({
                isMessagePopUp: true,
                showMessage: "Add chapter successfully",

                isApiPopUp: false,
              });
              setTimeout(
                () => this.setState({ showMessage: "", isMessagePopUp: false }),
                2000
              );
              this.chapterFormClose();
              this.getCourseTopic(course_id);
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                showMessage: "Chapter not added",
                isApiPopUp: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUpError: false,
                  }),
                2000
              );
            });
        }
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  handleChapterBackgroundImage = (e) => {
    this.setState({ chapter_bg_image: e.target.files[0] });
  };

  handleUseTemplate = (e) => {
    this.setState({ chapter_use_template: e.target.files[0] });
  };

  chapterFormReset = () => {
    this.setState({
      chapter_id: "",
      chapter_name: "",
      chapter_cat_name: "",
      chapter_template_type: "",
      chapter_use_template: "",
      use_template: "",
      chapter_title_image: "",
      sound_level: 0,
      sortby: "",
      chapter_bg_image: "",
      errors: "",
    });
  };

  handleShowChapter = (lesson_id) => {
    this.setState({ isChapterModelVisible: true, lesson_id: lesson_id });
    this.getCategotyList();
  };

  deleteLesson = () => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      const isDeleteVisible = this.state.courseDeleteList.filter(
        (item) => item._id == this.state.lessonDeleteId
      );

      this.setState({ isApiPopUp: true });
      if (isDeleteVisible[0].chapterdata.length == 0) {
        axios
          .delete(
            homepage.DeleteLesson +
              "/" +
              `${this.state.lessonDeleteId}?c=${today}`,
            {
              headers: header,
            }
          )
          .then((res) => {
            this.getCourseTopic(this.state.course_id);
            this.setState({
              isMessagePopUp: true,
              isApiPopUp: false,
              showMessage: "Delete Lesson successfully",
              isDeleteLessonSuccess: false,
            });
            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              4000
            );
            //   document
            //     .getElementById("section" + section_id)
            //     .classList.remove("show");
            //   document
            //     .getElementById("aaa" + section_id)
            //     .classList.add("collapsed");
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Lesson not deleted ,server error",
              isApiPopUp: false,
              isDeleteLessonSuccess: false,
            });
            setTimeout(
              () =>
                this.setState({ showMessage: "", isMessagePopUpError: false }),
              4000
            );
          });
      } else {
        this.setState({
          isMessagePopUpError: true,
          showMessage: "Delete Chapter First ",
          isApiPopUp: false,
          isDeleteLessonSuccess: false,
        });
        setTimeout(
          () => this.setState({ showMessage: "", isMessagePopUpError: false }),
          4000
        );
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  handleLessonBtnIcon = (e) => {
    this.setState({ lesson_btn_icon: e.target.files[0] });
  };

  handleLessonBtnImage = (e) => {
    this.setState({ lesson_btn_image: e.target.files[0] });
  };

  handleLessonBackgroundImage = (e) => {
    this.setState({ lesson_bg_image: e.target.files[0] });
  };

  handleLessonValidation = () => {
    const {
      lesson_name,
      lesson_btn_type,
      lesson_btn_icon,
      lesson_btn_image,
      lesson1_btn_image,
      lesson_bg_image,
      lesson1_bg_image,
      sortby,
    } = this.state;
    let errors = {};

    let formIsValid = true;
    this.setState({ errors });
    if (lesson_name == "") {
      formIsValid = false;
      errors["lesson_name"] = "Lesson name is required!";
    } else if (lesson_btn_type == "") {
      formIsValid = false;
      errors["lesson_btn_type"] = "Button type is required !";
    } else if (lesson_btn_type == "Horizontal") {
      if (lesson_btn_image == "" && lesson1_btn_image == "") {
        formIsValid = false;
        errors["lesson_btn_image"] = "Button Image is required !";
      } else if (lesson_bg_image == "" && lesson1_bg_image == "") {
        formIsValid = false;
        errors["lesson_bg_image"] = "Background image is required !";
      } else if (sortby == "") {
        formIsValid = false;
        errors["sortby"] = "Position is required !";
      }
    } else if (lesson_bg_image == "" && lesson1_bg_image == "") {
      formIsValid = false;
      errors["lesson_bg_image"] = "Background image is required !";
    } else if (sortby == "") {
      formIsValid = false;
      errors["sortby"] = "Position is required !";
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  handleEditCourse = (e) => {
    e.preventDefault();
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      if (this.handleCourseValidation()) {
        this.setState({ isBtnDisable: true, isApiPopUp: true });
        const {
          course_id,
          coursename,
          courseimg,
          coursebg,
          courseimg1,
          coursebg1,
        } = this.state;
        token = token.substring(1, token.length - 1);
        const formData = new FormData();
        formData.append("courseid", course_id);
        formData.append("name", coursename);
        formData.append("image", courseimg);
        formData.append("bg", coursebg);
        formData.append("image_old", courseimg1);
        formData.append("bg_old", coursebg1);

        axios
          .post(`${homepage.updateCourse}?c=${today}`, formData, {
            headers: header,
          })
          .then((response) => {
            this.setState({ isEditCourseVisible: false });
            this.getCourse(this.state.course_id);
            this.setState({
              errors: "",
              isMessagePopUp: true,
              showMessage: "Update course successfully",
              isBtnDisable: false,
              isApiPopUp: false,
            });
            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              2000
            );
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Course not updated",
              isApiPopUp: false,
            });
            setTimeout(
              () =>
                this.setState({ showMessage: "", isMessagePopUpError: false }),
              2000
            );
          });
      }
    }
  };

  handleCloseCourseEdit = () => {
    this.setState({ isEditCourseVisible: false });
  };

  handleDeleteLesson = (lesson_id, courseTopicList) => {
    this.setState({
      isDeleteLessonSuccess: true,
      lessonDeleteId: lesson_id,
      courseDeleteList: courseTopicList,
    });
  };

  handleDeleteTopic = (id, isChapterUnit) => {
    this.setState({
      isDeleteTopicSuccess: true,
      topicDeleteId: id,
      topicDeleteList: isChapterUnit,
    });
  };

  handleBtnImage = (e) => {
    this.setState({ btn_image: e.target.files[0] });
  };

  handleBgTopicImage = (e) => {
    this.setState({ topic_bg_image: e.target.files[0] });
  };

  topicFormClose = () => {
    this.setState({ isTopicModelVisible: false });
    this.topicFormReset();
  };

  handleChangeTopic = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleEditTopic = (topic_id) => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true, isBtnDisable: false });

      axios
        .get(topicpage.getTopicById + "/" + `${topic_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({
            errors: "",
            name: res.data.data.name != "null" ? res.data.data.name : "",
            btn_height:
              res.data.data.btn_height != "null"
                ? res.data.data.btn_height
                : "",
            btn_color:
              res.data.data.btn_color != "null" ? res.data.data.btn_color : "",
            btn_width:
              res.data.data.btn_width != "null" ? res.data.data.btn_width : "",
            btn_position:
              res.data.data.btn_position != "null"
                ? res.data.data.btn_position
                : "",
            topic_btn_image:
              res.data.data.btn_image != "null" ? res.data.data.btn_image : "",
            topic_bg_image:
              res.data.data.bg_image != "null" ? res.data.data.bg_image : "",
            topic1_bg_image:
              res.data.data.bg_image != "null" ? res.data.data.bg_image : "",
            topic_id: res.data.data._id != "null" ? res.data.data._id : "",
            isTopicModelVisible: true,
            isApiPopUp: false,
            bg_topic_image:
              res.data.data.bg_image != "null" ? res.data.data.bg_image : "",
            btn_font_color:
              res.data.data.btn_font_color != "null"
                ? res.data.data.btn_font_color
                : "",
            btn_font_size:
              res.data.data.btn_font_size != "null"
                ? res.data.data.btn_font_size
                : "",
            topic_btn_image1:
              res.data.data.btn_image != "null" ? res.data.data.btn_image : "",
          });
        })
        .catch((err) => {
          this.setState({ isApiPopUp: false });
          console.log(err);
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleTopicValidation = () => {
    const {
      name,
      title_image,
      btn_position,
      btn_image,
      bg_image,
      topic1_bg_image,
      topic_bg_image,
      bg_template,
      bg_topic_image,
      courseTopic,
    } = this.state;

    var checkleftBtnPosition =
      courseTopic &&
      courseTopic.filter((item) => item.btn_position == "left" && item);

    var checkRightBtnPostion =
      courseTopic &&
      courseTopic.filter((item) => item.btn_position == "right" && item);

    let errors = {};
    let formIsValid = true;
    this.setState({ errors });
    if (name == "") {
      formIsValid = false;
      errors["name"] = "Topic name is required!";
    } else if (topic1_bg_image == "" && topic_bg_image == "") {
      errors["bg_topic_image"] = "Background image is required !";
      formIsValid = false;
    } else if (btn_position == "") {
      errors["btn_position"] = "Button position is required !";
      formIsValid = false;
    } else if (btn_position == "left") {
      if (checkleftBtnPosition.length > 11) {
        errors["btn_position"] = "Button position Left Exceeded !";
        formIsValid = false;
      } else if (btn_position == "right") {
        if (checkRightBtnPostion.length > 11) {
          errors["btn_position"] = "Button position Right Exceeded !";
          formIsValid = false;
        }
      }
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  handleEditLesson = (lesson_id) => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      token = token.substring(1, token.length - 1);
      this.setState({ isApiPopUp: true });

      axios
        .get(lessonpage.getLessonById + "/" + `${lesson_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({
            errors: "",
            lesson_name: res.data.data.name != "null" ? res.data.data.name : "",
            lesson_btn_type:
              res.data.data.btn_type != "null" ? res.data.data.btn_type : "",
            lesson_btn_color:
              res.data.data.btn_color != "null" ? res.data.data.btn_color : "",
            lesson_btn_height:
              res.data.data.btn_height != "null"
                ? res.data.data.btn_height
                : "",
            lesson_btn_width:
              res.data.data.btn_width != "null" ? res.data.data.btn_width : "",
            lesson_btn_image:
              res.data.data.btn_image != "null" ? res.data.data.btn_image : "",
            lesson1_btn_image:
              res.data.data.btn_image != "null" ? res.data.data.btn_image : "",
            lesson1_bg_image:
              res.data.data.lesson_bg_image != "null"
                ? res.data.data.lesson_bg_image
                : "",
            lesson_bg_image:
              res.data.data.lesson_bg_image != "null"
                ? res.data.data.lesson_bg_image
                : "",
            lesson1_btn_icon:
              res.data.data.btn_icon != "null" ? res.data.data.btn_icon : "",
            lesson_id: res.data.data._id != "null" ? res.data.data._id : "",
            isLessonModelVisible: true,
            isApiPopUp: false,
            old_lesson1_btn_image:
              res.data.data.btn_image != "null" ? res.data.data.btn_image : "",
            lesson_btn_font_color:
              res.data.data.btn_font_color != "null"
                ? res.data.data.btn_font_color
                : "",
            lesson_btn_font_size:
              res.data.data.btn_font_size != "null"
                ? res.data.data.btn_font_size
                : "",
            sortby: res.data.data.sortby,
          });
        })
        .catch((err) => {
          this.setState({ isApiPopUp: false });
          console.log(err);
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleAddTopic = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);

      if (this.handleTopicValidation()) {
        this.setState({ isBtnDisable: true, isApiPopUp: true });
        const {
          course_id,
          topic_id,
          name,
          btn_color,
          btn_height,
          btn_width,
          btn_position,
          btn_image,
          btn_font_size,
          btn_font_color,
          topic_bg_image,
          topic_btn_image1,
          bg_topic_image,
        } = this.state;
        const formData = new FormData();
        formData.append("course_id", course_id);
        formData.append("name", name);
        formData.append("btn_color", btn_color);
        formData.append("btn_height", btn_height);
        formData.append("btn_width", btn_width);
        formData.append("btn_position", btn_position);
        formData.append("btn_image", btn_image);
        formData.append("bg_image", topic_bg_image);
        formData.append("old_bg_image", bg_topic_image);
        formData.append("old_btn_image", topic_btn_image1);
        formData.append("btn_font_size", btn_font_size);
        formData.append("btn_font_color", btn_font_color);

        if (topic_id) {
          formData.append("topic_id", topic_id);
          axios
            .post(`${topicpage.updateTopic}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.topicFormClose();
              this.getCourseTopic(this.state.course_id);
              this.topicFormReset();
              this.setState({
                isMessagePopUp: true,
                showMessage: "Update topic successfully",
                isBtnDisable: false,
                isApiPopUp: false,
              });
              setTimeout(
                () => this.setState({ showMessage: "", isMessagePopUp: false }),
                2000
              );
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                showMessage: "Topic not update",
                isApiPopUp: false,
              });
              this.topicFormReset();
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUpError: false,
                  }),
                2000
              );
            });
        } else {
          axios
            .post(`${topicpage.addTopic}?c=${today}`, formData, {
              headers: header,
            })
            .then((res) => {
              if (res.status == 200) {
                this.topicFormClose();

                this.getCourseTopic(this.state.course_id);
                this.topicFormReset();
                this.setState({
                  showMessage: "Add topic successfully",
                  isMessagePopUp: true,
                  isBtnDisable: false,
                  isApiPopUp: false,
                });
                setTimeout(
                  () =>
                    this.setState({ showMessage: "", isMessagePopUp: false }),
                  2000
                );
              } else {
                this.getCourseTopic(this.state.course_id);

                this.setState({
                  errorMessage: res.data.message,
                  isMessagePopUpError: true,
                  isBtnDisable: false,
                  isApiPopUp: false,
                });
                setTimeout(
                  () =>
                    this.setState({
                      errorMessage: "",
                      isMessagePopUpError: false,
                    }),
                  2000
                );
              }
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                showMessage: "Topic not add",
                isApiPopUp: false,
              });
              this.topicFormReset();
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUpError: false,
                  }),
                2000
              );
            });
        }
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  handleShowLesson = (topic_id) => {
    this.setState({ isLessonModelVisible: true, topicid: topic_id });
  };

  topicFormReset = () => {
    this.setState({
      name: "",
      title_image: "",
      btn_color: "#ffffff",
      btn_size: "",
      btn_position: "",
      btn_image: "",
      bg_template: "",
      topic_id: "",
      topic_btn_image: "",
      topic_bg_image: "",
      topic1_bg_image: "",
      topic_title_image: "",
      btn_font_size: "",
      btn_font_color: "#000000",
      btn_height: "",
      btn_width: "",
      sortby: "",
      errors: "",
    });
  };

  getCourseLesson = (topic_id) => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      axios
        .get(lessonpage.getLesson + "/" + `${topic_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          if (res) {
            this.setState({ courseLesson: res.data.data });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleAddLesson = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);
      if (this.handleLessonValidation()) {
        this.setState({ isBtnDisable: true, isApiPopUp: true });
        const {
          topicid,
          lesson_id,
          course_id,
          lesson_name,
          lesson_btn_color,
          lesson_btn_height,
          lesson_btn_width,
          lesson_btn_type,
          lesson_btn_icon,
          lesson_btn_image,
          lesson_bg_image,
          lesson_btn_font_size,
          lesson_btn_font_color,
          old_lesson1_btn_image,
          lesson1_btn_icon,
          sortby,
        } = this.state;
        const formData = new FormData();
        formData.append("course_id", course_id);
        formData.append("topic_id", topicid);
        formData.append("name", lesson_name);
        formData.append("btn_color", lesson_btn_color);
        formData.append("btn_height", lesson_btn_height);
        formData.append("lesson_bg_image", lesson_bg_image);
        formData.append("btn_width", lesson_btn_width);
        formData.append("btn_type", lesson_btn_type);
        formData.append("btn_icon", lesson_btn_icon);
        formData.append("btn_image", lesson_btn_image);
        formData.append("old_btn_icon", lesson1_btn_icon);
        formData.append("old_btn_image", old_lesson1_btn_image);
        formData.append("btn_font_size", lesson_btn_font_size);
        formData.append("btn_font_color", lesson_btn_font_color);
        formData.append("sortby", sortby);
        if (lesson_id) {
          formData.append("lesson_id", lesson_id);
          axios
            .post(`${lessonpage.updateLesson}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.lessonFormClose();
              this.getCourseTopic(course_id);
              this.setState({
                isMessagePopUp: true,
                showMessage: "Update lesson successfully",
                isBtnDisable: false,
                isApiPopUp: false,
              });
              setTimeout(
                () => this.setState({ showMessage: "", isMessagePopUp: false }),
                2000
              );
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                showMessage: "Lesson not update",
                isApiPopUp: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUpError: false,
                  }),
                2000
              );
            });
        } else {
          axios
            .post(`${lessonpage.addLesson}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.lessonFormClose();
              this.getCourseTopic(course_id);
              this.setState({
                isMessagePopUp: true,
                showMessage: "Add lesson successfully",
                isBtnDisable: false,
                isApiPopUp: false,
              });
              setTimeout(
                () => this.setState({ showMessage: "", isMessagePopUp: false }),
                2000
              );
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                showMessage: "Lesson not added",
                isApiPopUp: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUpError: false,
                  }),
                2000
              );
            });
        }
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  lessonFormClose = () => {
    this.setState({ isLessonModelVisible: false });
    this.lessonFormReset();
  };

  lessonFormReset = () => {
    this.setState({
      lesson_id: "",
      lesson_name: "",
      lesson_btn_color: "#ffffff",
      lesson_btn_height: "",
      lesson_btn_width: "",
      lesson_btn_type: "",
      lesson_btn_icon: "",
      lesson_btn_image: "",
      lesson1_btn_image: "",
      lesson1_btn_icon: "",
      lesson1_btn_image: "",
      lesson_bg_image: "",
      lesson1_bg_image: "",
      lesson_btn_font_size: "",
      errors: "",
      sortby: "",
      lesson_btn_font_color: "#000000",
    });
  };

  getCategotyList = () => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      token = token.substring(1, token.length - 1);

      axios
        .get(`${homepage.GetCategory}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ categoryLists: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getCourseTopic = (course_id) => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");

    if (token) {
      this.setState({ isApiPopUp: true });
      axios
        .get(topicpage.getCourseDetails + "/" + `${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          if (res.data.data) {
            this.setState({ courseTopic: res.data.data, isApiPopUp: false });
          }
        })
        .catch((error) => {
          this.setState({ isApiPopUp: false });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleTopicToggle = (topic_id) => {
    var cName = document.getElementById("topic" + topic_id).className;
    if (cName.indexOf("show") == -1) {
      document.getElementById("topic" + topic_id).classList.add("show");
      document.getElementById("aa" + topic_id).classList.remove("collapsed");
    } else {
      document.getElementById("topic" + topic_id).classList.remove("show");
      document.getElementById("aa" + topic_id).classList.add("collapsed");
    }
  };

  handleSectionToggle = (section_id) => {
    this.setState({ courseChapter: "", chapterId: section_id });

    var scName = document.getElementById("section" + section_id).className;
    if (scName.indexOf("show") == -1) {
      document.getElementById("section" + section_id).classList.add("show");
      document.getElementById("aaa" + section_id).classList.remove("collapsed");
    } else {
      document.getElementById("section" + section_id).classList.remove("show");
      document.getElementById("aaa" + section_id).classList.add("collapsed");
    }
  };

  handleShowTopic = () => {
    this.setState({ isTopicModelVisible: true });
  };

  handleShowCourse = () => {
    this.setState({ isEditCourseVisible: true });
  };

  componentWillMount() {
    const course_id = localStorage.getItem("course_id");
    this.getCourseTopic(course_id);
    this.setState({ course_id });
  }

  componentDidMount() {
    const { course_id } = this.state;
    this.getCourse(course_id);
    this.getCourseTopic(course_id);
  }

  handleCourseValidation = () => {
    const {
      coursename,
      courseimg,
      coursebg,
      courseimg1,
      coursebg1,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    this.setState({ errors });
    if (coursename == "") {
      formIsValid = false;
      errors["coursename"] = "Course name is required !";
    } else if (courseimg == "" && courseimg1 == "") {
      formIsValid = false;
      errors["courseimg1"] = "Course image is require !";
    } else if (coursebg == "" && coursebg1 == "") {
      errors["coursebg1"] = "Course background image is require !";
      formIsValid = false;
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  getCourse = (course_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true });
      token = token.substring(1, token.length - 1);
      var today = Math.round(new Date().getTime() / 1000);
      axios
        .get(homepage.getCourseById + "/" + `${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          if (res) {
            this.setState({
              coursename: res.data.data.name,
              courseimg1: res.data.data.image,
              isApiPopUp: false,
              coursebg1: res.data.data.bg,
            });
          }
        })
        .catch((error) => {
          this.setState({ isApiPopUp: false });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleCourseImage = (e) => {
    this.setState({ courseimg: e.target.files[0] });
  };

  handleCourseBg = (e) => {
    this.setState({ coursebg: e.target.files[0] });
  };

  deleteTopic = () => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true });
      const isDelete = this.state.topicDeleteList.filter(
        (item) => item._id == this.state.topicDeleteId && item.lessondata
      );

      if (isDelete[0].lessondata.length == 0) {
        axios
          .delete(
            homepage.DeleteTopic +
              "/" +
              `${this.state.topicDeleteId}?c=${today}`,
            {
              headers: header,
            }
          )
          .then((res) => {
            this.setState({
              isMessagePopUp: true,
              isDeleteTopicSuccess: false,
              isApiPopUp: false,
              showMessage: "Delete Topic successfully",
            });
            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              2000
            );
            // document.getElementById("section" + id).classList.remove("show");
            // document
            //   .getElementById("aaa" + section_id)
            //   .classList.add("collapsed");
            this.getCourseTopic(this.state.course_id);
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Topic not deleted, server error",
              isApiPopUp: false,
              isDeleteTopicSuccess: false,
            });
            setTimeout(
              () =>
                this.setState({
                  showMessage: "",
                  isMessagePopUpError: false,
                }),
              4000
            );
          });
      } else {
        this.setState({
          isMessagePopUpError: true,
          showMessage: "This topic is not empty, please delete it's content",
          isApiPopUp: false,
          isDeleteTopicSuccess: false,
        });
        setTimeout(
          () => this.setState({ showMessage: "", isMessagePopUpError: false }),
          4000
        );
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    var today = Math.round(new Date().getTime() / 1000);
    const courseTopic = reorder(
      this.state.courseTopic,
      result.source.index,
      result.destination.index
    );

    this.setState({
      courseTopic,
    });

    var updateArr = [];
    courseTopic.forEach(function (val, index) {
      updateArr.push({ id: val._id, sortby: index + 1 });
    });
    if (updateArr.length > 0) {
      var token = localStorage.getItem("accessToken");
      if (token) {
        token = token.substring(1, token.length - 1);

        const sortData = { sortData: updateArr };
        axios
          .post(`${lessonpage.sortTopic}?c=${today}`, sortData, {
            headers: jsonHeader,
          })
          .then((response) => {
            this.setState({
              isMessagePopUp: true,
              showMessage: "Sort topic successfully",
            });
            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              2000
            );
            this.getCourseTopic(this.state.course_id);
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Topic not sorted",
            });
            setTimeout(
              () =>
                this.setState({ showMessage: "", isMessagePopUpError: false }),
              2000
            );
          });
      } else {
        this.setState({ redirect: true });
      }
    }
  };

  render() {
    const {
      courseTopic,
      topic_id,
      isChapterModelVisible,
      chapter_id,
      errors,
      chapter_cat_name,
      formIsValid,
      chapter_name,
      categoryLists,
      chapter_template_type,
      chapter_bg_image,
      chapter1_bg_image,
      sortby,
      use_template,
      isBtnDisable,
      isEditCourseVisible,
      coursename,
      courseimg1,
      coursebg1,
      isLessonModelVisible,
      lesson_id,
      lesson_name,
      lesson_btn_type,
      lesson_btn_color,
      lesson_btn_height,
      lesson_btn_width,
      lesson1_btn_image,
      lesson_btn_icon,
      lesson_bg_image,
      lesson1_bg_image,
      lesson1_btn_icon,
      lesson_btn_font_size,
      isTopicModelVisible,
      btn_color,
      btn_height,
      btn_width,
      btn_position,
      btn_font_size,
      btn_font_color,
      topic_btn_image,
      topic_bg_image,
      topic1_bg_image,
      name,
      isDeleteTopicSuccess,
      isApiPopUp,
      showMessage,
      errorMessage,
      isMessagePopUp,
      isMessagePopUpError,
      isDeleteLessonSuccess,
      isDeleteSuccess,
    } = this.state;

    const error_text = { color: "red" };

    return (
      <>
        <div className="right-panel tab-content ">
          <div id="module" className="tab-pane in active">
            <div className="title-heading">
              <h2>Module Section</h2>
              <div className="addContent">
                <button
                  className="btn btn-add"
                  data-toggle="modal"
                  onClick={this.handleShowTopic}
                >
                  <span>
                    <i className="fas fa-plus"></i>
                  </span>
                  Add Topic
                </button>

                <button
                  className="btn btn-add"
                  data-toggle="modal"
                  onClick={this.handleShowCourse}
                >
                  <span>
                    <i className="far fa-edit"></i>
                  </span>
                  Edit Course
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="module-content">
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="accordion md-accordion"
                          id="accordionTop"
                        >
                          {Array.isArray(courseTopic) &&
                            courseTopic.map((item, inde) => (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={inde}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="card topicList"
                                  >
                                    <div className="card-header topic-heading">
                                      <a
                                        className="collapsed"
                                        id={"aa" + item._id}
                                        data-toggle="collapse"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleTopicToggle(item._id)
                                        }
                                      >
                                        <span className="qvos-arrow">
                                          <i className="fas fa-chevron-right"></i>
                                        </span>
                                        {item.name}
                                      </a>
                                      <div className="actionBtn">
                                        <div
                                          className="editTitle"
                                          data-toggle="modal"
                                          onClick={() =>
                                            this.handleShowLesson(item._id)
                                          }
                                        >
                                          <i className="fas fa-plus"></i>

                                          <span>Add Lesson</span>
                                        </div>
                                        <div
                                          className="editTitle"
                                          data-toggle="modal"
                                          onClick={() =>
                                            this.handleEditTopic(item._id)
                                          }
                                        >
                                          <i className="far fa-edit"></i>

                                          <span>Edit</span>
                                        </div>
                                        <div
                                          className="editTitle"
                                          data-toggle="modal"
                                          onClick={() =>
                                            this.handleDeleteTopic(
                                              item._id,
                                              courseTopic
                                            )
                                          }
                                        >
                                          <i className="far fa-trash-alt"></i>

                                          <span>Delete</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id={"topic" + item._id}
                                      className="collapse in active "
                                      data-parent="#accordionTop"
                                    >
                                      <div className="card-body">
                                        <div
                                          className={`accordion md-accordion ${item._id}`}
                                          id="accordionEx"
                                        >
                                          {item.lessondata.map((val, index) => (
                                            <div className="card" key={val._id}>
                                              <div className="card-header">
                                                <span className="count">
                                                  {index + 1}
                                                </span>
                                                <a
                                                  className="collapsed"
                                                  data-toggle="collapse"
                                                  href="javascript:void(0);"
                                                  id={"aaa" + val._id}
                                                  onClick={() =>
                                                    this.handleSectionToggle(
                                                      val._id
                                                    )
                                                  }
                                                >
                                                  <span className="qvos-arrow">
                                                    <i className="fas fa-chevron-right"></i>
                                                  </span>
                                                  {val.name}
                                                </a>
                                                <div className="actionBtn">
                                                  <div
                                                    className="editTitle"
                                                    data-toggle="modal"
                                                    onClick={() =>
                                                      this.handleShowChapter(
                                                        val._id
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-plus"></i>

                                                    <span>Add Chapter</span>
                                                  </div>
                                                  <div
                                                    className="editTitle"
                                                    data-toggle="modal"
                                                    onClick={() =>
                                                      this.handleEditLesson(
                                                        val._id
                                                      )
                                                    }
                                                  >
                                                    <i className="far fa-edit"></i>

                                                    <span>Edit</span>
                                                  </div>
                                                  <div
                                                    className="editTitle"
                                                    data-toggle="modal"
                                                    onClick={() =>
                                                      this.handleDeleteLesson(
                                                        val._id,
                                                        item.lessondata
                                                      )
                                                    }
                                                  >
                                                    <i className="far fa-trash-alt"></i>

                                                    <span>Delete</span>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                id={"section" + val._id}
                                                className="collapse"
                                                role="tabpanel"
                                                aria-labelledby="featureThree3"
                                                data-parent="#accordionEx"
                                              >
                                                <div className="card-body">
                                                  {val.chapterdata.map(
                                                    (result, i) => (
                                                      <div
                                                        className="chapterList"
                                                        key={result._id}
                                                      >
                                                        <h4>
                                                          {i + 1}. {result.name}
                                                          <div className="actionBtn">
                                                            <div
                                                              className="editTitle"
                                                              data-toggle="modal"
                                                              onClick={() =>
                                                                this.handleEditChapter(
                                                                  result._id
                                                                )
                                                              }
                                                            >
                                                              <i className="far fa-edit"></i>

                                                              <span>Edit </span>
                                                            </div>
                                                            <div
                                                              className="editTitle"
                                                              data-toggle="modal"
                                                              onClick={() =>
                                                                this.handleDeleteChapter(
                                                                  result._id,
                                                                  val._id
                                                                )
                                                              }
                                                            >
                                                              <i className="far fa-trash-alt"></i>

                                                              <span>
                                                                Delete{" "}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </h4>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isChapterModelVisible && (
          <div className="modal" id="addLesson" style={{ display: "block" }}>
            <div className="modal-dialog addPopup modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    {chapter_id ? "Edit" : "Add"} Chapter
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.chapterFormClose}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Chapter Name{" "}
                          <span className="text-danger error-text">
                            {!formIsValid && errors.chapter_name}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={chapter_name}
                          name="chapter_name"
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Chapter Category{" "}
                          {/* <span class="text-danger error-text">
                            {!formIsValid && errors.chapter_cat_name}
                          </span> */}
                        </label>
                        <select
                          className="form-control"
                          name="chapter_cat_name"
                          value={chapter_cat_name}
                          onChange={this.handleChangeTopic}
                        >
                          <option value="0">Select</option>
                          {Array.isArray(categoryLists) &&
                            categoryLists.map((item) => (
                              <option
                                value={item._id}
                                id={item.name}
                                key={item._id}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Template Type{" "}
                          <span className="text-danger error-text">
                            {!formIsValid && errors.chapter_template_type}
                          </span>
                        </label>
                        <select
                          className="form-control"
                          name="chapter_template_type"
                          value={chapter_template_type}
                          onChange={this.handleChangeTopic}
                        >
                          <option value="">Select</option>
                          <option value="HTML">HTML</option>
                          <option value="Video">Video</option>
                          <option value="SWF">SWF</option>
                          <option value="JPG">JPG</option>
                          <option value="PNG">PNG</option>
                        </select>
                      </div>
                    </div>
                    {/*  */}
                    <div className="col-lg-6">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>Background Image</label>
                          <span className="text-danger error-text">
                            {!formIsValid && errors.chapter_bg_image}
                          </span>
                          <input
                            type="file"
                            name="chapter_bg_image"
                            accept={background_img_type}
                            onChange={this.handleChapterBackgroundImage}
                          />
                        </div>
                        {chapter1_bg_image && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={chapter1_bg_image}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>{" "}
                    {/*  */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Position (OrderBy)
                          <span className="text-danger error-text">
                            {!formIsValid && errors.sortby}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="sortby"
                          value={sortby}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>
                            Template Use{" "}
                            <span className="text-danger error-text">
                              {!formIsValid && errors.use_template}
                            </span>
                          </label>
                          <input
                            type="file"
                            name="use_template"
                            onChange={this.handleUseTemplate}
                          />
                        </div>
                        {use_template && (
                          <a
                            className="smallImage"
                            href={use_template}
                            target="_blanck"
                          >
                            <i className="far fa-image"></i>
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 button-group">
                      <button
                        type="button"
                        className="btn btn-loaderButton"
                        onClick={this.handleAddChapter}
                        disabled={isBtnDisable}
                      >
                        <span className="btn-text">Submit</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={this.chapterFormReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isEditCourseVisible && (
          <div className="modal" id="addCourse" style={{ display: "block" }}>
            <div className="modal-dialog addPopup modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Edit Course</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.handleCloseCourseEdit}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>
                          Course Name{" "}
                          <span className="text-danger error-text">
                            {!formIsValid && errors.name}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="coursename"
                          value={coursename}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>
                            Course Image
                            <span
                              className="text-danger error-text"
                              style={error_text}
                            >
                              {!formIsValid && errors.courseimg1}
                            </span>
                          </label>{" "}
                          <input
                            type="file"
                            name="courseimg"
                            accept={background_img_type}
                            onChange={this.handleCourseImage}
                          />
                        </div>

                        {courseimg1 && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={courseimg1}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>
                            Course Background
                            <span className="text-danger error-text">
                              {!formIsValid && errors.coursebg1}
                            </span>
                          </label>{" "}
                          <input
                            type="file"
                            name="coursebg"
                            accept={background_img_type}
                            onChange={this.handleCourseBg}
                          />
                        </div>
                        {coursebg1 && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={coursebg1}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 button-group">
                      <button
                        type="button"
                        className="btn btn-loaderButton"
                        onClick={this.handleEditCourse}
                        disabled={isBtnDisable}
                      >
                        <span className="btn-text">Update</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLessonModelVisible && (
          <div className="modal" id="addLesson" style={{ display: "block" }}>
            <div className="modal-dialog addPopup modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    {lesson_id ? "Edit" : "Add"} Lesson
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.lessonFormClose}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Name
                          <span className="text-danger error-text">
                            {!formIsValid && errors.lesson_name}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lesson_name"
                          value={lesson_name}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Button Type
                          <span className="text-danger error-text">
                            {!formIsValid && errors.lesson_btn_type}
                          </span>
                        </label>
                        <select
                          className="form-control"
                          name="lesson_btn_type"
                          value={this.state.lesson_btn_type}
                          onChange={this.handleChangeTopic}
                        >
                          <option value="0">Select</option>
                          <option value="Horizontal">
                            Horizontal Book Cover
                          </option>
                          <option value="Vertical">Button Vertical</option>
                          <option value="2_colomn">
                            Button 2 Colomn Horizontal
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {lesson_btn_type != "Horizontal" && (
                    <div className="notHorizontal row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Button Colour</label>
                          <input
                            type="color"
                            className="colorForm-control"
                            name="lesson_btn_color"
                            value={this.state.lesson_btn_color}
                            onChange={this.handleChangeTopic}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Button Height</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lesson_btn_height"
                            value={lesson_btn_height}
                            onChange={this.handleChangeTopic}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Button Width</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lesson_btn_width"
                            value={lesson_btn_width}
                            onChange={this.handleChangeTopic}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Button Font Colour</label>
                          <input
                            type="color"
                            className="colorForm-control"
                            name="lesson_btn_font_color"
                            value={this.state.lesson_btn_font_color}
                            onChange={this.handleChangeTopic}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Button Font Size</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lesson_btn_font_size"
                            value={lesson_btn_font_size}
                            onChange={this.handleChangeTopic}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group imageBox">
                          <div className="input-Image">
                            <label>Button Icon</label>
                            <input
                              type="file"
                              name="lesson_btn_icon"
                              onChange={this.handleLessonBtnIcon}
                            />
                          </div>
                          {lesson1_btn_icon && (
                            <div className="viewImage">
                              <span className="closeImage">
                                <i className="fas fa-times-circle"></i>
                              </span>
                              <a
                                className="smallImage"
                                href={lesson1_btn_icon}
                                target="_blanck"
                              >
                                <i className="far fa-image"></i>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>Button Image</label>
                          <span className="text-danger error-text">
                            {!formIsValid && errors.lesson_btn_image}
                          </span>
                          <input
                            type="file"
                            name="lesson_btn_image"
                            accept={button_img_type}
                            onChange={this.handleLessonBtnImage}
                          />
                        </div>
                        {lesson1_btn_image && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={this.state.lesson_btn_image}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>Background Image</label>
                          <span className="text-danger error-text">
                            {!formIsValid && errors.lesson_bg_image}
                          </span>
                          <input
                            type="file"
                            name="lesson_bg_image"
                            accept={background_img_type}
                            onChange={this.handleLessonBackgroundImage}
                          />
                        </div>
                        {lesson1_bg_image && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={lesson1_bg_image}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Position (OrderBy)
                          <span className="text-danger error-text">
                            {!formIsValid && errors.sortby}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="sortby"
                          value={sortby}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 button-group">
                      <button
                        type="button"
                        className="btn btn-loaderButton"
                        onClick={this.handleAddLesson}
                        disabled={isBtnDisable}
                      >
                        <span className="btn-text">Submit</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={this.lessonFormReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isTopicModelVisible && (
          <div className="modal" id="addTopic" style={{ display: "block" }}>
            <div className="modal-dialog addPopup modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    {topic_id ? "Edit" : "Add"} Topic{" "}
                  </h4>

                  <button
                    type="button"
                    className="close"
                    onClick={this.topicFormClose}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Name{" "}
                          <span className="text-danger error-text">
                            {!formIsValid && errors.name}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>
                          Button Position{" "}
                          <span className="text-danger error-text">
                            {!formIsValid && errors.btn_position}
                          </span>
                        </label>
                        <select
                          className="form-control"
                          name="btn_position"
                          value={btn_position}
                          onChange={this.handleChangeTopic}
                        >
                          <option value="0">Select</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                          <option value="footer">Footer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Button Colour</label>
                        <input
                          type="color"
                          className="colorForm-control"
                          name="btn_color"
                          value={btn_color}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Button Height</label>
                        <input
                          type="text"
                          className="form-control"
                          name="btn_height"
                          value={btn_height}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Button Width</label>
                        <input
                          type="text"
                          className="form-control"
                          name="btn_width"
                          value={btn_width}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Button Font Colour</label>
                        <input
                          type="color"
                          className="colorForm-control"
                          name="btn_font_color"
                          value={btn_font_color}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Button Font Size</label>
                        <input
                          type="text"
                          className="form-control"
                          name="btn_font_size"
                          value={btn_font_size}
                          onChange={this.handleChangeTopic}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>
                            Background Image{" "}
                            <span className="text-danger error-text">
                              {!formIsValid && errors.bg_topic_image}
                            </span>
                          </label>
                          <input
                            type="file"
                            accept={background_img_type}
                            name="topic_bg_image"
                            onChange={this.handleBgTopicImage}
                          />
                        </div>
                        {topic1_bg_image && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={topic1_bg_image}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group imageBox">
                        <div className="input-Image">
                          <label>Button Image</label>
                          <input
                            type="file"
                            name="btn_image"
                            accept={button_img_type}
                            onChange={this.handleBtnImage}
                          />
                        </div>
                        {topic_btn_image && (
                          <div className="viewImage">
                            <span className="closeImage">
                              <i className="fas fa-times-circle"></i>
                            </span>
                            <a
                              className="smallImage"
                              href={topic_btn_image}
                              target="_blanck"
                            >
                              <i className="far fa-image"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 button-group">
                      <button
                        type="button"
                        className="btn btn-loaderButton"
                        onClick={this.handleAddTopic}
                        disabled={isBtnDisable}
                      >
                        <span className="btn-text">Submit</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={this.topicFormReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Loader isApiPopUp={isApiPopUp} />

        <ShowMessage
          showMessage={showMessage}
          errorMessage={errorMessage}
          isMessagePopUp={isMessagePopUp}
          isMessagePopUpError={isMessagePopUpError}
        />

        <DeleteTopicModal
          isDeleteTopicSuccess={isDeleteTopicSuccess}
          DeleteTopicClose={() =>
            this.setState({ isDeleteTopicSuccess: false })
          }
          handleDeleteTopicSure={this.deleteTopic}
        />

        <DeleteLessonModal
          isDeleteLessonSuccess={isDeleteLessonSuccess}
          isDeleteLessonClose={() =>
            this.setState({ isDeleteLessonSuccess: false })
          }
          handleDeleteLessonSure={this.deleteLesson}
        />

        <DeleteChapterModal
          isDeleteSuccess={isDeleteSuccess}
          deleteChapterClose={() => this.setState({ isDeleteSuccess: false })}
          handleDeleteChapterSure={this.handledeleteChapter}
        />
      </>
    );
  }
}

export default CourseModule;
